
import { useState, useEffect } from "react";

//Bootstrap Components Import
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from "react-bootstrap/Modal";

// Icons Imports
import { HiArrowRight } from 'react-icons/hi';
import { BsWindows } from 'react-icons/bs';

//Plugin used to render markdown
import ReactMarkdown from 'react-markdown'

//Plugin to detect mobile
import {BrowserView, MobileView} from 'react-device-detect';

const ProductCard = (props) => {
  
  // Const for the Details Bootstrap Modal
  const [modalvisible, showModal] = useState(false)
  const showDetails = () => showModal(true);
  const closeDetails = () => showModal(false);
  
  //Import the readmefile
  const [readmedata, setReadMe] = useState('');
  useEffect(() => {
    fetch(props.readme)
    .then(res => res.text())
    .then(res => setReadMe(res))
    .catch(err => console.log(err));
  });
  
  const [mobilereadmedata, setMobileReadMe] = useState('');
  useEffect(() => {
    fetch(props.mobilereadme)
    .then(res => res.text())
    .then(res => setMobileReadMe(res))
    .catch(err => console.log(err));
  });
  
  return (
    
    <div>
    
    <BrowserView>
    <Card style={{ minHeight: '5vh', height: '50vh',width: '25vw', flex: 1, margin: '0.5rem', color:'white', backgroundColor: 'rgba(38,38,38,0.9)'}}>
    
    <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
    alignItems: 'center',justifyContent: 'center',paddingTop: '5px'}}>
    
    <Card.Img style={{ maxWidth: '64px', maxHeight: '64px'}} variant="top" 
    src={process.env.PUBLIC_URL + props.logo} />
    </div>
    
    <Card.Body>
    <Card.Title>{props.title}</Card.Title>
    
    <Card.Text style={{fontSize: '14px'}}>
    {props.desc}
    <br></br>
    <p style={{marginBottom: '0px', fontSize: '12px',position: 'absolute', bottom: 60}}>Current Version :  {props.version}</p>
    </Card.Text>
    
    <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center',
    justifyContent: 'center'}}>
    
    <Button onClick={showDetails} variant="outline-dark" style={{color:'white',border: '1px solid white',position: 'absolute', bottom: 18,alignSelf: 'center',justifySelf: 'center'}}>
    ReadMe & Download <HiArrowRight style={{color:'white', alignSelf: 'center',justifySelf: 'center'}}/></Button>
    
    
    <Modal size="lg" fullscreen='md-down' scrollable centered show={modalvisible} style={{ color:'white', backgroundColor: 'rgba(0,0,0,0.2)'}}>
    
    <Modal.Header style={{ color:'white', backgroundColor: 'rgba(38,38,38,0.9)'}}><h3>{props.title}</h3>
    <Button style={{color:'white',border: '1px solid white'}} onClick={closeDetails} variant="outline-dark">X</Button>
    </Modal.Header>
    <Modal.Body style={{ color:'white', backgroundColor: 'rgba(38,38,38,0.9)'}}>
    
    <BrowserView>
    <ReactMarkdown>{readmedata}</ReactMarkdown>
    </BrowserView>
    <MobileView>
    <ReactMarkdown>{mobilereadmedata}</ReactMarkdown>
    </MobileView>
    
    
    <br/>
    <Button href={props.downloadlink} variant="outline-dark" style={{color:'white', alignSelf: 'center',justifySelf: 'center', border: '1px solid white'}}>
    <BsWindows style={{ alignSelf: 'center',justifySelf: 'center'}}/> Download</Button>
    </Modal.Body>
    
    </Modal>
    </div>
    </Card.Body>
    </Card>
    </BrowserView>

    {/* Mobile Version 
    ==================================================== 
    */}

    <MobileView>
    <Card style={{ minHeight: '5vh', height: '45vh',width: '75vw', flex: 1, margin: '0.5rem', color:'white', backgroundColor: 'rgba(38,38,38,0.9)'}}>
    
    <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
    alignItems: 'center',justifyContent: 'center',paddingTop: '5px'}}>
    
    <Card.Img style={{ maxWidth: '64px', maxHeight: '64px'}} variant="top" 
    src={process.env.PUBLIC_URL + props.logo} />
    </div>
    
    <Card.Body>
    <Card.Title>{props.title}</Card.Title>
    
    <Card.Text style={{fontSize: '14px'}}>
    {props.desc}
    <br></br>
    <p style={{marginBottom: '0px', fontSize: '12px',position: 'absolute', bottom: 60}}>Current Version :  {props.version}</p>
    </Card.Text>
    
    <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center',
    justifyContent: 'center'}}>
    
    <Button onClick={showDetails} variant="outline-dark" style={{color:'white',border: '1px solid white',position: 'absolute', bottom: 18,alignSelf: 'center',justifySelf: 'center'}}>
    ReadMe & Download <HiArrowRight style={{color:'white', alignSelf: 'center',justifySelf: 'center'}}/></Button>
    
    
    <Modal size="lg" fullscreen='md-down' scrollable centered show={modalvisible} style={{ color:'white', backgroundColor: 'rgba(0,0,0,0.2)'}}>
    
    <Modal.Header style={{ color:'white', backgroundColor: 'rgba(38,38,38,0.9)'}}><h3>{props.title}</h3>
    <Button style={{color:'white',border: '1px solid white'}} onClick={closeDetails} variant="outline-dark">X</Button>
    </Modal.Header>
    <Modal.Body style={{ color:'white', backgroundColor: 'rgba(38,38,38,0.9)'}}>
    
    <BrowserView>
    <ReactMarkdown>{readmedata}</ReactMarkdown>
    </BrowserView>
    <MobileView>
    <ReactMarkdown>{mobilereadmedata}</ReactMarkdown>
    </MobileView>
    
    
    <br/>
    <Button href={props.downloadlink} variant="outline-dark" style={{color:'white', alignSelf: 'center',justifySelf: 'center', border: '1px solid white'}}>
    <BsWindows style={{ alignSelf: 'center',justifySelf: 'center'}}/> Download</Button>
    </Modal.Body>
    
    </Modal>
    </div>
    </Card.Body>
    </Card>
    </MobileView>
    
    
    
    
    </div>
    )
  }
  
  export default ProductCard
  