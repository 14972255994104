
import BlogInfo from "./BlogInfo";

const headerstyle = {
    header: {
        display: 'flex', flexDirection: 'column', flexWrap: 'wrap', padding: '0.4rem',backgroundColor: 'rgba(0,0,0, 0)'
    },
};

const SiteHeader = () => {
    return (
        <div>
        <header className="App-header" style={headerstyle.header} >
        <img src="/img/logo.png" className="App-logo" alt="logo" style={{alignSelf:'center',justifySelf:'center',maxWidth: '240px', maxHeight: '512px',filter: 'invert(1)',paddingTop: '0.2rem',paddingLeft: '0.2rem'}} />
        <BlogInfo/>
        </header>
        
        </div>
        )
    }
    
    export default SiteHeader
    