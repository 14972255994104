import Button from "react-bootstrap/Button"
import Popover from "react-bootstrap/Popover"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"


const BlogInfo = () => {
    return (
        <>
        <OverlayTrigger
        trigger="hover"
        key="bottom"
        placement="bottom"
        overlay={
            <Popover id={`popover-positioned-bottom`}>
            <Popover.Body style={{ color:'white', backgroundColor: 'rgba(0,0,0,0.9)'}}>
            <p>
            A blog analyzing and discussing technical and development topics, products, troubleshooting and stories.
            <br></br>
            Take a look, you'll love it 😉
            </p>
            </Popover.Body>
            </Popover>
        } style={{ color:'black', backgroundColor: 'rgba(0,0,0,0.9)'}}
        
        >
        <Button variant="dark" style={{alignSelf:'center',justifySelf:'center', height: '3rem', width: '15rem',color:'white',border: '1px solid white', right: 0}}
         onClick={(e) => {
            e.preventDefault();
            window.location.href='https://blog.ekronds.co.za';
            }}> From the Tech Front</Button>
        
        </OverlayTrigger>
        
        
        
        </>
        )
    }
    
    export default BlogInfo
    