import SiteHeader from "./components/SiteHeader";
import ProductCard from "./components/ProductCard";
import CardGroup from "react-bootstrap/CardGroup";


function App() {
  return (
    <div className="App" style={{ 
      backgroundImage: `url("img/background.jpeg")`,
      backgroundSize: 'cover',
      minHeight: '100vh',
      fontFamily: 'Georgia', fontStyle: 'normal', fontWeight: 'normal'
    }}>
    
    <SiteHeader/>
    
    <CardGroup style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center',
    justifyContent: 'center'}} >
    
    <ProductCard version="2022.08.18" downloadlink="https://github.com/c0der4t/EagleEye/releases/download/2022.08.18/EagleEye.exe" logo="/repo/eagleeye/logo.png" title="Eagle Eye FileWatcher" desc="Execute A Program Or A Command When A Specified File Is Created Or Changed On Your Computer" readme="/repo/eagleeye/readme.md" mobilereadme="/repo/eagleeye/mobilereadme.md"/>
    <ProductCard version="1.0.0.0" downloadlink="/repo/stringman/StringMan.exe" logo="/repo/stringman/logo.png" title="String Man" desc="A Priceless Tool To Have In Your Arsenal. Manipulate Text Easily And Complete Repetitive Work Instantly." readme="/repo/stringman/readme.md" mobilereadme="/repo/stringman/mobilereadme.md"/>
    <ProductCard version="1.0.0.0" downloadlink="/repo/pslaunchpad/PowerShell_Launchpad.exe" logo="/repo/pslaunchpad/logo.png" title="PS Launchpad" desc="Launch PowerShell Scripts Like A Boss. A Simple Tool That Allows You To Launch PS Scripts From An App-Like Hub" readme="/repo/pslaunchpad/readme.md" mobilereadme="/repo/pslaunchpad/mobilereadme.md"/>
    <ProductCard version="1.0.0.0" downloadlink="/repo/filejanitor/FileJanitor.exe" logo="/repo/filejanitor/logo.png" title="FileJanitor" desc="A small utility used to clean those pesky files other applications leave to grow to infinity" readme="/repo/filejanitor/readme.md" mobilereadme="/repo/filejanitor/mobilereadme.md"/>
    {/* <ProductCard version="Concept Release (unstable)" downloadlink="/repo/est/EasyStockTake_Concept.exe" logo="/repo/est/logo.jpg" title="Easy Stock Take" desc="Streamline Your Stocktakes With This Dynamic Soltion Which Attempts To Conform To Any Stock File Given To It." readme="/repo/est/readme.md" mobilereadme="/repo/est/mobilereadme.md"/> */}
    <ProductCard version="1.0.0 - Jackdaw" downloadlink="/repo/filerenamer/FileRenamer.exe" logo="/repo/filerenamer/logo.png" title="FileRenamer" desc="A Small And Effective Tool. Rename Multiple Files All At Once Using Custom Rules. All Done In Seconds" readme="/repo/filerenamer/readme.md" mobilereadme="/repo/filerenamer/mobilereadme.md"/>
    <ProductCard version="1.0.0.0" downloadlink="/repo/plainnato/Plain NATO.exe" logo="/repo/plainnato/logo.png" title="Plain NATO" desc="A Small Tool That Converts Words To Nato Code As You Type. Aimed For Use During Technical Phone Calls" readme="/repo/plainnato/readme.md" mobilereadme="/repo/plainnato/mobilereadme.md"/>
    <ProductCard version="1.4.0.0" downloadlink="/repo/printkicker/IQPrinterKicker.exe" logo="/repo/printkicker/logo.png" title="Printer Kicker" desc="Interface That Calls The Kick Command On A Printer With The Given Kick String - CLI Available" readme="/repo/printkicker/readme.md" mobilereadme="/repo/printkicker/mobilereadme.md"/>
    <ProductCard version="1.0.0.0" downloadlink="/repo/UTF8_Convert/UTF8_Convert_Setup.exe" logo="/repo/UTF8_Convert/logo.png" title="UTF8 Convert Script Generator" desc="Generates A Hybrid Script You Can Call To Convert A Specified File To UTF8 Format." readme="/repo/UTF8_Convert/readme.md" mobilereadme="/repo/UTF8_Convert/mobilereadme.md"/>
    <ProductCard version="1.0.0.0" downloadlink="/repo/driverexport/DriverExport.zip" logo="/repo/driverexport/logo.png" title="Driver Export Script" desc="A Simple Batch Script That Exports All Third Party Drivers On A Windows Machine To The Designated Directory." readme="/repo/driverexport/readme.md" mobilereadme="/repo/driverexport/mobilereadme.md"/>


    </CardGroup>
    
    
    
    {/* End off App div */} 
    </div>
    );
  }
  
  export default App;
  